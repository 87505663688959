// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-list-js": () => import("./../../../src/components/blog-list.js" /* webpackChunkName: "component---src-components-blog-list-js" */),
  "component---src-components-blog-post-js": () => import("./../../../src/components/blog-post.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-components-term-list-js": () => import("./../../../src/components/term-list.js" /* webpackChunkName: "component---src-components-term-list-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-talks-js": () => import("./../../../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */)
}

